import React from "react"
import './Redstone.css'
import redstoneImg from '../../images/redstoneImg.png';
const openDownloadUrl = () =>{
    window.open("http://adfoc.us/6973171")
}
const openWiki = () =>{
    window.open("https://wiki.inceptiondev.xyz/redstone")
}
function Redstone(){
    return (
        <div className="main">
            <h1 className="header">Redstone Plugin</h1>
            <img className={"logo"} src={redstoneImg} alt={redstoneImg} />
            <button className={"download"} color={""} onClick={openDownloadUrl}>Download</button>
            <button className={"wiki"} color={""} onClick={openWiki}>Wiki</button>
        </div>
    )
}
export default Redstone;
